@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.marketAssetDetail {
  width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .topArea {
    flex: 0 0 35%;
    max-height: calc(30% + 15px);
    display: flex;
    flex-direction: column-reverse;
    border-bottom: 1px solid $border-color;
    .breadCrumbs {
      display: none;
      align-items: center;
      margin-top: 16px;
      padding: 0 80px;
      height: 14px;
      span {
        font-size: 14px;
        line-height: 1;
        cursor: pointer;
        &.gt {
          margin: 0 4px;
        }
        &:last-child {
          font-weight: 600;
          text-decoration: underline;
        }
        &:hover {
          font-weight: 600;
        }
      }
    }
    .assetRow {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 3rem;
      margin: auto 0;
      .selectedAsset {
        display: flex;
        align-items: center;
        margin-top: 30px;
        img {
          height: 70px;
          width: 70px;
          margin-right: 8px;
        }
        .names {
          flex: 1;
          line-height: 1;
          font-size: 28px;
          .coinName {
            display: flex;
            align-items: center;
            font-weight: 600;
            .assetCoin {
              margin-left: 10px;
              display: flex;
              padding: 6px;
              border: 1px solid $border-color;
              border-radius: 5px;
              @include btn-animate;
              img {
                height: 16px;
                width: 16px;
              }
              span {
                font-size: 15px;
                font-weight: 600;
              }
            }
          }
          .symbol {
            margin-top: 10px;
            font-size: 16px;
            display: flex;
            align-items: center;
            line-height: 1;
            img {
              height: 18px;
              width: 18px;
              margin-right: 3px;
              object-fit: contain;
            }
            span {
              @include btn-animate;
              img {
                border-radius: 9px;
                object-fit: cover;
              }
              &:nth-of-type(2) {
                margin: 0 12px;
                padding: 0 12px;
                border-left: 1px solid $border-color;
                border-right: 1px solid $border-color;
              }
            }
          }
        }
      }
      .price,
      .roi {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .value {
          font-size: 24px;
          font-weight: 600;
          span {
            font-weight: 400;
          }
        }
        .label {
          font-size: 12px;
        }
      }
      // .roi {
      //   align-items: flex-end;
      //   .value {
      //     color: $primary-green;
      //   }
      // }
      .btns {
        display: flex;
        height: 100%;
        align-items: center;
        .btnBuy,
        .btnSell,
        .btnShare {
          flex: 0 0 120px;
          width: 120px;
          height: 40px;
          border: 1px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          border-radius: 35px;
          font-weight: 600;
          color: white;
          @include btn-animate(white);
        }
        .btnBuy {
          background: $primary-green;
        }
        .btnSell {
          background: $primary-red;
        }
        .btnShare {
          border-color: $border-color;
          color: $primary-color;
          @include btn-animate;
        }
      }
    }
    .bottomRow {
      flex: 0 0 45px;
      height: 45px;
      display: flex;
      // border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      padding: 0 3rem;
      .tabList {
        height: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        .tab {
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 3%;
          cursor: pointer;
          white-space: nowrap;
          height: 32.5px;
          padding: 0 20px;
          &:hover {
            font-weight: 500;
          }
          &.true {
            font-weight: 600;
            background: rgba(229, 229, 229, 0.25);
            border-radius: 35px;
          }
        }
      }
    }
  }
  .aboutChart {
    // height: 0;
    height: auto;
    // flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 50px 3rem;
    padding-top: 0rem;
    .about {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 6%;
      .title {
        font-size: 20px;
        font-weight: 600;
      }
      .desc {
        font-size: 16px;
        line-height: 2;
      }
      .valuesList {
        height: 0;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        .value {
          flex: 0 0 33%;
          margin: 5% 0;
          .head {
            font-size: 14px;
            font-weight: bold;
          }
          .val {
            font-size: 21px;
            font-weight: lighter;
          }
        }
      }
    }
    .chart {
      flex: 0 0 47%;
      display: flex;
      flex-direction: column;
      border: 1px solid $border-color;
      border-radius: 25px;
      overflow: hidden;
      .chartHead {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;
        border-bottom: 1px solid $border-color;
        .titleRow {
          display: flex;
          justify-content: space-between;
          .title {
            display: flex;
            align-items: center;
            font-size: 28px;
            font-weight: bold;
            white-space: nowrap;
            svg {
              height: 20px;
              width: auto;
              margin-left: 8px;
              transition: all 400ms ease;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
          .switchList {
            display: flex;
            align-items: center;
            .switch {
              height: 30px;
              width: 35px;
              margin-left: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              cursor: pointer;
              &:hover,
              &.true {
                background: rgba(229, 229, 229, 0.25);
              }
            }
          }
        }
        .marketValues {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          span {
            font-weight: 600;
            color: $primary-green;
            margin-left: 6px;
          }
        }
      }
      .chartFooter {
        flex: 0 0 40px;
        border-top: 1px solid $border-color;
        padding: 0 50px;
        display: flex;
        align-items: center;
        .footerItem {
          font-size: 14px;
          flex: 0 0 14%;
        }
      }
    }
  }
}
