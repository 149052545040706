.Prospectus {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .capitalized-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    // padding-right: 3rem;

    .capitalized-icon {
      max-width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #212529;
      // margin-right: 1rem;
      img {
        width: 80%;
        height: 80%;
        min-width: 160px;
        min-height: 40px;
      }
    }

    .search-capitalized {
      width: 40%;
      height: 100%;
      border: 0.5px solid #ededed;
      background: rgba(237, 237, 237, 0.37);
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.37);
      border: 1.5px solid #ededed;
      border-radius: 35px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      // margin-left: 0.5rem;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #212529;
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #212529;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #212529;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .capitalized-listBtn {
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4caf50;
      opacity: 0.9;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 600;
      color: white;
      font-size: 1rem;
      // margin-left: 20vw;
      white-space: nowrap;
    }
  }

  .w-space {
    width: 100%;
    height: 5.5%;
    padding: 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: max-content;
      gap: 1.5rem;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 1.5rem;
        // pointer-events: none;
        p {
          margin-bottom: 0;
          color: #5f6163 !important;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          align-items: center;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }

        img {
          height: 1.1rem;
          object-fit: contain;
        }
      }

      .each-option-selected {
        font-weight: 600;
        background: rgba(237, 237, 237, 0.37);
        border-radius: 25px;
        color: #5f6163;
      }
      .topIcons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .capitalized-content {
    width: 100%;
    height: calc(100% - 13.5%);

    .companyHeader {
      width: 100%;
      height: 3rem;
      background: rgba(237, 237, 237, 0.37);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 500;
      padding-left: 3rem;
      border-bottom: 0.5px solid #ededed;
    }

    .companyContent {
      width: 100%;
      height: calc(100% - 3rem);
      overflow-y: scroll;

      .eachCompany {
        width: 100%;
        padding: 2rem 0 2rem 3rem;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.02);
        }
        div {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          padding-right: 10px;
          color: #5f6163;
        }
        label {
          width: 146px;
          height: 41px;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          color: #5f6163;

          background: rgba(255, 255, 255, 0.37);
          border: 1px solid #ededed;
          border-radius: 35px;
        }

        .companyDetails {
          width: 30%;
          display: flex;
          gap: 10px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .offeringsContent {
      width: 100%;
      height: calc(100% - 3rem);
      overflow-y: scroll;

      .eachOffering {
        width: 100%;
        padding: 2rem 0 2rem 3rem;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.02);
        }

        .OfferingDetails {
          width: 25%;
          display: flex;
          gap: 10px;

          .img1 {
            width: 40px;
            height: 40px;
          }

          .img2 {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  .popular {
    position: fixed;
    height: calc(100% - 13.5%);
    top: 13.5%;
    border-left: 0.5px solid #e5e5e5;
    // border-top: 0.5px solid #e5e5e5;
    width: 30%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;
    right: 0;

    .head-txt {
      font-size: 1.75rem;
      font-weight: 550;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 6rem;

      .close-div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .each-action {
      display: flex;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      padding: 2rem 1.5rem;
      margin-top: 1rem;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.04);
      }

      div {
        font-weight: 500;
      }
    }

    .overall-div {
      width: 100%;
      height: calc(100% - 11rem);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .subbTxt {
        font-size: 1.2rem;
        font-weight: 550;
      }

      .upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2rem 0rem;
        margin-top: 1rem;

        .imgUpload {
          border: 0.5px solid #e7e7e7;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .each-action1 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem 1.5rem;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        div {
          font-weight: 500;
        }

        img {
          width: 25px;
          height: 25px;
        }

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .separatorDiv {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
        }

        .hiddenDIv {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }

      .each-action2 {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        margin-top: 1rem;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }

        input {
          width: 80%;
          height: 100%;
          border: none;
          padding: 2rem 1.5rem;
          background: transparent;
          &::placeholder {
            font-weight: 550;
          }
        }

        .currencyDiv {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 0.5px solid #e5e5e5;
          font-weight: 700;
        }
      }

      .successMsg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
      }
    }

    .successMsgg {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      margin: auto;
    }

    .stepBtns {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      div {
        width: 49%;
        border-radius: 35px;
        color: #ffffff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .popular1 {
    position: fixed;
    height: calc(100% - 13.5%);
    top: 13.5%;
    border-left: 0.5px solid #e5e5e5;
    width: 30%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 0 0 2rem 0;
    transition: all ease-in 0.4s;
    right: 0;

    .head-txt {
      font-size: 1.75rem;
      font-weight: 550;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 6rem;
      padding: 0 2rem;

      .close-div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .overall-div {
      width: 100%;
      height: calc(100% - 6rem);
      overflow-y: scroll;

      .subbTxt {
        font-size: 0.9rem;
        font-weight: 200;
        padding-left: 2rem;
        margin-bottom: 1.5rem;
      }

      .each-action {
        display: flex;
        align-items: center;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem 1.5rem;
        // margin-top: 1rem;
        transition: all ease 0.2s;
        gap: 10px;
        width: calc(100% - 4rem);
        margin: 1rem auto 0;

        &:hover {
          transform: scale(1.04);
        }

        div {
          font-weight: 500;
        }

        img {
          width: 40px;
          height: 40px;
        }
      }

      .coverPic-container {
        width: 100%;
        height: 19rem;
        position: relative;

        .coverPicDiv {
          width: 100%;
          height: 15rem;
          border-bottom: 0.5px solid #e5e5e5;
          border-top: 0.5px solid #e5e5e5;
          z-index: 1;
        }

        .logoDiv {
          width: 120px;
          height: 120px;
          position: absolute;
          left: 2rem;
          top: 11rem;
          z-index: 2;
          background: #ffffff;
          border-radius: 50%;
          border: 1px solid #e5e5e5;
        }
      }

      .inputs-container {
        width: 100%;
        padding: 0 2rem;
      }

      .stepBtns {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: space-between;
        /* padding: 0 2rem; */
        margin-top: 2.5rem;

        div {
          width: 49%;
          border-radius: 35px;
          color: #ffffff;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .successMsgg {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      margin: auto;
    }
  }
}
