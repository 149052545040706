.topNavbar {
  width: 100%;
  height: 85px;
  border-bottom: 0.5px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;

  & > .vaultsIcon {
    width: 10%;

    & > img {
      width: 100%;
      max-height: 100%;
    }
  }

  & > .searchVaults {
    width: 45%;
    height: 100%;
    border: 0.5px solid #ededed;
    background: hsla(0, 0%, 92.9%, 0.37);
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #5f6163;

    & > div {
      //   padding: 0.5rem 2rem;
    }

    & > .dropDown {
      width: 30%;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;

      & > div {
        white-space: nowrap;
        & > svg {
          font-size: 2.5rem;
        }
      }
    }

    & > .searchDiv {
      width: 70%;
      height: 100%;

      & > input {
        width: 100%;
        border: none;
        outline: none;
        height: 100%;
        background: transparent;

        &::placeholder {
          font-weight: 700;
          color: #5f6163;
        }
      }
    }

    &:hover {
      border: 1.5px solid black;
    }
  }

  & > .balance {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-right: 0.5px solid #ededed;

    & > div {
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;
      font-weight: 600;
      color: #5f6163;

      & > .amount {
        // opacity: 1;
        font-size: 1.2rem;
        color: #5f6163;
      }
    }
  }

  & > .balanceActions {
    width: 24%;
    display: flex;
    justify-content: space-between;
    // align-items: ;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      border-radius: 15px;
      font-size: 1rem;
      font-weight: 600;
      color: white;
      padding: 0.5rem 1rem;
      white-space: nowrap;
      cursor: pointer;
    }

    & > .addMore {
      background: #4dc089;
    }

    & > .withdraw {
      background: #cd1e25;
    }
  }
}

.wSpace {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  height: 5.65%;
  padding: 0rem 3rem;
  border-bottom: 0.5px solid #e7e7e7;
  justify-content: space-between;

  .optionss {
    width: max-content;
    gap: 1.5rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;

    & > .tabs {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    & > .selectedTab {
      background: rgba(237, 237, 237, 0.37);
      border-radius: 25px;
      padding: 0rem 1.5rem;
      height: 90%;
      font-size: 0.9rem;
    }

    .eachOption {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 1.5rem;
      // pointer-events: none;
      p {
        margin-bottom: 0;
        color: #5f6163 !important;
        font-size: 0.9rem;
      }
      div {
        display: flex;
        align-items: center;
        gap: 5px;
        transition: all ease 0.3s;
        cursor: pointer;
        &:hover {
          scale: 1.1;
        }
      }

      img {
        height: 1.1rem;
        object-fit: contain;
      }
    }

    .eachOptionSeleted {
      font-weight: 600;
      background: rgba(237, 237, 237, 0.37);
      border-radius: 25px;
      color: #5f6163;
    }
    .topIcons {
      display: flex;
      align-items: center;
      height: 100%;
      div {
        height: 100%;
        padding: 0 2rem;
        display: flex;
        border-right: 0.25px solid #e7e7e7;
        align-items: center;
        img {
          height: 50%;
        }
      }
    }
  }

  & > .lastOption {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0rem;

    .toggle {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;

      .togglecomp {
        // border: 1px solid red;
        width: 10rem;
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          margin-bottom: 0;
        }
        .toggle-div {
          margin-left: 0;
          margin-top: -16px;
        }
      }
    }

    .changeAppVault {
      display: flex;
      height: 100%;
      gap: 0.5rem;
      background: rgba(255, 255, 255, 0.37);
      border: 1px solid #ededed;
      border-radius: 25px;
      padding: 5px 0;
      width: max-content;
      max-width: 220px;
      min-width: max-content;

      & > div {
        font-size: 0.8rem;
        padding: 0px 1rem;
        font-weight: 550;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          height: 100%;
          max-height: 30px;
          object-fit: contain;
        }

        & > span {
          margin-left: 10px;
        }
      }
      & > .selectApp {
        font-size: 0.8rem;
        padding: 0.25rem 1rem;
        font-weight: 550;
        border-left: 1px solid #ededed;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease-in 0.2s;

        &:hover {
          // scale: 1.05;
          text-decoration: underline;
        }
      }
    }
  }
}

.decksContent {
  width: 100%;
  height: calc(100% - 13.65%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & > div {
    border: 0.5px solid #ededed;
  }
}

.settingsNavbar {
  width: 100%;
  height: 8%;
  border-bottom: 0.5px solid #e7e7e7;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 1rem 3rem;
  // padding-right: 3rem;
  position: relative;

  .settingsIcon {
    max-width: 18%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 1.35rem;
    font-weight: 650;
    color: #5f6163;
    margin-right: 1rem;
    img {
      width: 80%;
      height: 80%;
      min-width: 160px;
      min-height: 40px;
    }
  }

  .searchSettings {
    width: 43%;
    height: calc(100% - 2rem);
    border: 0.5px solid #e7e7e7;
    background: #fff;
    border-radius: 25px;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    .all {
      width: 32%;
      height: 100%;
      border-right: 3px solid #e7e7e7;
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: #5f6163;
      font-weight: 700;
      opacity: 0.25;
      justify-content: space-between;
      padding-right: 15px;
      p {
        margin-bottom: 0;
      }
      .arroww {
        display: flex;
        align-items: center;
        img {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }
    }
    .im {
      width: 100%;
      height: 100%;
      // padding-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      color: #5f6163;
      font-weight: 700;
      // opacity: 0.25;
      gap: 1rem;
      input {
        border: none;
        font-size: 1rem;
        color: #5f6163;
        font-weight: 700;
        background: transparent;
      }
    }
  }

  .settingsListBtn {
    position: absolute;
    right: 3rem;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4caf50;
    opacity: 0.9;
    border-radius: 35px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    font-size: 1rem;
    // margin-left: 20vw;
    white-space: nowrap;
  }
}
