// New Hero Section

.heroParent {
  display: flex;
  // padding: 0px 3rem;
  position: relative;
  min-height: 500px;
  background: #f5f5f5;

  & > .topSection {
    height: 200px;
    width: 100%;
    background: linear-gradient(90deg, #4caf50 0%, #93d996 100%);
  }

  & > .offeringsVideo {
    position: absolute;
    width: calc(40% - 3rem);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 3rem;
    transform: translate(0, -50%);

    & > img {
      width: 90%;
      object-fit: contain;
      cursor: pointer;
      border-radius: 15px;
    }
    iframe {
      width: 100%;
      object-fit: contain;
      border-radius: 15px;
      cursor: pointer;
    }
  }
}

.statParent {
  // display: flex;
  padding: 0px 3rem;
  padding-top: 30px;

  .statContainer {
    display: flex;
    align-items: center;
    // color: white;
    position: relative;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   height: 0.5px;
    //   background: #e5e5e5;
    //   width: 100%;
    //   left: 0;
    //   top: 0;
    // }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   height: 0.5px;
    //   background: #e5e5e5;
    //   width: calc(100% + 6rem);
    //   left: -3rem;
    //   bottom: -47px;
    // }
    & > div {
      padding: 1rem 0;
      padding-bottom: 0.5rem;
    }

    & > :nth-child(2),
    :nth-child(3) {
      position: relative;
      padding-top: 2rem;

      &::before {
        content: '';
        position: absolute;
        width: 0.5px;
        background: #e5e5e5;
        height: calc(100% + 42px);
        left: -50px;
        top: 0;
        bottom: 0;
      }
      & > :nth-child(1) {
        color: #5f6163;
      }
    }
  }

  .customProgress {
    width: 182px;
    margin-top: 10px;
    margin-bottom: 45px;
    // border: 1px solid #5f6163; /* Border color for the progress bar */
    border-radius: 4px;
    overflow: hidden;
    height: 8px;
  }

  .customProgress::-webkit-progress-bar {
    background-color: #e5e5e5; /* Background color for the unprogressed portion */
  }

  .customProgress::-webkit-progress-value {
    background-color: #4caf50; /* Color for the progressed portion */
  }

  .statItem {
    margin-right: 150px;

    :nth-child(1) {
      font-size: 30px;
      font-weight: 600;
      // margin-bottom: 10px;
      color: #4caf50;
    }
    :nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      // opacity: 0.5;
    }
  }

  .statContainer {
    position: relative;
    width: max-content;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   height: 0.5px;
    //   background: #e5e5e5;
    //   width: 100%;
    //   left: 0;
    //   top: 0;
    // }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   height: 0.5px;
    //   background: #e5e5e5;
    //   width: calc(100% + 6rem);
    //   left: -3rem;
    //   bottom: -47px;
    // }
  }
}

.heroContainer {
  // background-color: white;
  color: #5f6163;
  padding-right: 1rem;
  width: calc(60% - 3rem);
  position: absolute;
  left: 3rem;
  top: 100px;

  // & > progress::-webkit-progress-bar {
  //   background-color: green;
  // }
}

.breadcrumbContainer {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  font-weight: 700;
  font-size: 18px;
  padding-bottom: 90px;
  :nth-child(1) {
    color: white;
    opacity: 0.5;
  }
  :nth-child(2) {
    margin-right: 10px;
    color: white;
    opacity: 0.5;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      opacity: 1;
      color: white;
    }
  }

  // &:hover{
  //     cursor: pointer;

  //     // text-decoration: underline;
  // }
}

.backtoMainSite {
  &:hover {
    cursor: pointer;
    color: white;
    opacity: 1;
  }
}

.companyContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding-bottom: 25px;
  padding-top: 38px;
  gap: 1rem;

  & > :nth-child(1) {
    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 10px;
    aspect-ratio: 1/1;
    max-width: 120px;
    max-height: 120px;
    background: white;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .companyName {
    // margin-left: 15px;
    :nth-child(1) {
      font-size: 30px;
      font-weight: 600;
    }
  }
  .companyDesc {
    font-size: 18px;
    font-weight: 400;
    // opacity: 0.5;

    & > .companyDescInside {
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      // transition: all ease-in 0.2s;

      &:hover {
        text-decoration: underline;
        // font-weight: 600;
      }
    }
  }
}

.categoryGrid {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
  }

  div {
    // border: 1px solid #e5e5e5;
    border-radius: 25px;
    padding: 10px 25px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 40px;
    background: white;
    display: flex;
    gap: 0.35rem;
    align-items: center;
    transition: all ease-in 0.2s;

    &:hover {
      cursor: pointer;
      scale: 1.05;
      color: #5f6163;
    }
  }
}

.stickyRight {
  position: fixed;
  // height: 700px;
  width: 25%;
  top: 150px;
  right: 0;
  margin-right: 5vw;
  background-color: white;
  border-radius: 25px;
  z-index: 9999;
  border: 1px solid #e7e7e7;
  padding: 42px 37px;
  div {
    position: sticky;
    top: 80px;
  }
}

.actionTitle {
  font-size: 32px;
  font-weight: 800;
  padding-bottom: 30px;
}

.actionGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    padding: 0px 20px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    &:hover {
      cursor: pointer;
      background-color: #e7e7e7;
    }
  }
}
.selectedAction {
  background: #4caf50;
  // background-color: #E7E7E7;
  color: white;
  font-weight: 800;
  font-size: 13px;
}
.actionHeader {
  font-weight: 700;
  font-size: 18px;
  color: #5f6163;
  padding-bottom: 33px;
}
.offeringItem {
  display: flex;
  align-items: center;
  border: 0.5px solid #e7e7e7;
  border-radius: 10px;
  padding: 38px 26px;
  font-weight: 600;
  font-size: 18px;
  color: #5f6163;
  margin-bottom: 16px;
  :nth-child(2) {
    padding-left: 20px;
  }
  &:hover {
    cursor: pointer;
    background-color: #e7e7e7;
  }
}
.getStartedBtn {
  background: #4caf50;
  border-radius: 35px;
  color: white;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0px;
  margin-top: 40px;
  cursor: pointer;
}

// Old

section {
  padding-top: 7vh;
}

.templateContainer {
  padding: 30px 15vw;
  padding-right: 9vw;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 90px;
}
.templateContainer1 {
  padding: 30px 0px;
  padding-left: 0vw;
  padding-right: 1rem;
  display: grid;
  grid-template-columns: 0.45fr 1.8fr 0.6fr;
  gap: 50px;
  position: relative;
  padding-top: 40px;
  border-top: 1px solid #e5e5e5;
}
.farLeftSide {
  // position: fixed;
  // width: 120px;
  // height: 100vh;
  // top: 80px;
  // background-color: red;
  font-size: 15px;
  padding-left: 3rem;
  // border-right: 1px solid #e7e7e7;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 40px);
    background: #e7e7e7;
    right: 0;
    top: -40px;
    bottom: 0;
  }
}
.menuContainer {
  position: sticky;
  top: 10px;
  div {
    padding: 10px 0px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
.iconsDiv {
  display: flex;
  align-items: center;
  font-size: 25px;
  :nth-child(1) {
    margin-right: 20px;
    &:hover {
      cursor: pointer;
      color: #0049ff;
    }
  }
  :nth-child(2) {
    &:hover {
      cursor: pointer;
      color: #0049ff;
    }
  }
}
.headerContainer {
  padding: 0px 15vw;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.headerTitle {
  font-size: 50px;
  font-weight: bold;
  padding-left: 15px;
  color: #5f6163;
}
.headerSubTitle {
  padding: 0px 15vw;
  font-size: 20px;
}
.badgeContainer {
  display: flex;
  // justify-content: space-between;
  padding: 0px 15vw;
  padding-top: 30px;
  div {
    margin-right: 10px;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 12px;
    background-color: rgb(238, 238, 238);
    border-color: rgb(238, 238, 238);
    &:hover {
      background-color: rgb(217, 216, 216);
      border-color: rgb(217, 216, 216);
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.slideContainer {
  width: 100%;
  // min-height: 505px;
}
.slideThumbnails {
  display: flex;
  border: 3px solid transparent;
}
.thumb {
}
.selectedThumb {
  border: 3px solid #0049ff;
  padding: 5px;
}

.badgeSpecial {
  margin-right: 10px;
  padding: 5px 6px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid #f83a5c;
  color: #f83a5c;
  display: flex;
  align-items: center;
  &:hover {
    // background-color: rgb(217, 216, 216);
    border-color: #f83a5c;
    font-weight: 500;
    cursor: pointer;
  }
}
.badge {
  margin-right: 10px;
  padding: 5px 6px;
  border-radius: 3px;
  font-size: 12px;
  background-color: rgb(238, 238, 238);
  border-color: rgb(238, 238, 238);
  &:hover {
    background-color: rgb(217, 216, 216);
    border-color: rgb(217, 216, 216);
    font-weight: 500;
    cursor: pointer;
  }
}

//Right Side

.totalAmount {
  color: #19c157;
  margin-bottom: 4px;
  font-size: 35px;
  font-weight: 900;
  letter-spacing: 0.01em;
}
.totalAmountSub {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #5f6163;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.sectionContainer {
  padding-top: 20px;
  padding-bottom: 0px;
}
.title {
  font-size: 35px;
  font-weight: 800;
  color: #5f6163;
  display: flex;
  align-items: center;
}
.subtitle {
  font-size: 18px;
  font-weight: 400;
  color: #5f6163;
  // padding-bottom: 10px;
}

.investButton {
  background-color: rgb(0, 73, 255);
  width: 100%;
  padding: 12px 25px;
  font-size: 16px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

.hiddenDiv {
  position: sticky;
  top: 0;
  background-color: #5f6163;
  height: 60px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5vw;
}

.stickeyTopLeft {
  display: flex;
  align-items: center;
  font-size: 12px;
  div {
    margin-right: 10px;
    font-size: 20px;
    font-weight: 800;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
}
.stickeyTopRight {
  font-weight: 700;
  font-size: 16px;
}

// Tab styles

.stickyHeader {
  display: none;
  position: sticky;
  top: 0px;
  z-index: 1;
  // padding: 10px 0px;
  color: #5f6163;
  background-color: rgba(237, 237, 237);
}
.tabInvestButton {
  // margin-right: 15vw;
  background-color: rgb(0, 73, 255);
  width: 350px;
  // height: 30px;
  padding: 12px 30px;
  font-size: 17px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  text-align: center;
}
.stickyHeader .isStuck {
  background-color: red;
}
.tabsContainer {
  padding: 10px 0px;
  // gap: 50px;
  display: flex;
  // padding-left: 15vw;
}
.backtoTop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.5;
  cursor: pointer;
}
.tabs {
  padding-right: 50px;
  font-size: 18px;
  font-weight: 600;
  // color: rgb(179, 179, 179);
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #5f6163;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
.selectedTab {
  margin-right: 50px;
  font-size: 18px;
  color: #5f6163;
  // border-spacing: 20px;
  // text-underline-offset: 20px;

  // border-bottom: 3px solid rgb(0, 73, 255);
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  // color: rgb(0, 73, 255);
  // :nth-child(1){
  //     text-decoration: underline;
  //     text-decoration-thickness: 4px;

  // }
}

.numberBadge {
  // display: inline-block;
  // margin-left: 2px;
  // padding: 5px;
  // background-color: rgb(238, 238, 238);
  // font-size: 16px;
  // line-height: 1.1em;
  // font-weight: 400;
  // color: rgb(106, 106, 106);
  // border-radius: 3px;
  display: inline-block;
  margin-left: 5px;
  padding: 1px 6px 3px;
  background-color: rgb(238, 238, 238);
  font-size: 16px;
  line-height: 1.1em;
  font-weight: 400;
  color: #5f6163;
  border-radius: 3px;
}

.sectionTitle {
  font-size: 25px;
  font-weight: 600;
  color: #5f6163;
  // padding-bottom: 10px;
}
.smallhr {
  width: 56px;
  margin: 24px 0px;
  border: 1px solid rgb(238, 238, 238);
  // color: rgb(102, 102, 102);
}
.vipCardContainer {
  display: flex;
}
.vipCard {
  margin-right: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  min-height: 104px;
  padding: 16px 60px 16px 24px;
  background-color: rgb(238, 210, 155);
  box-shadow: rgb(0 0 0 / 6%) 0px 4px 16px;
  border-radius: 5px;
  overflow: hidden;
  outline: none;
  cursor: default;
  transition: background-color 0.1s ease-in-out 0s;
}
.vipCardContent {
  width: 160px;
  :nth-child(1) {
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #5f6163;
  }
  :nth-child(2) {
    padding-top: 10px;
    font-size: 12px;
    line-height: 1.4em;
    font-weight: 400;
    letter-spacing: -0.015em;
    color: #5f6163;
  }
}
.vipCardIcon {
  // width: 100px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 50px;
  background-color: rgb(245, 217, 163);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: opacity 0.1s ease-in-out 0s, background-color;
  img {
    max-width: 30px;
    max-height: 34px;
    filter: invert(72%) sepia(15%) saturate(1241%) hue-rotate(355deg)
      brightness(114%) contrast(84%);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #5f6163;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 9999999;
    top: -20px;
    // bottom: 150%;
    left: 50%;
    margin-left: -60px;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

.bulletPoints {
  padding-top: 20px;
  li {
    word-break: break-word;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 1.6;
    color: #5f6163;
  }
}

.contentText {
  margin-bottom: 30px;
  line-height: 1.6;
  font-size: 20px;
  color: #5f6163;
}
.termWrapper {
  border-bottom: 1px solid rgb(238, 238, 238);
  // padding-bottom: 20px;
}
.termItem {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  font-size: 18px;
}
.termDesc {
  font-size: 11px;
  font-weight: 400;
  color: #5f6163;
  padding-bottom: 10px;
  line-height: 1.6;
}
.learnMore {
  font-size: 11px;
  font-weight: 400;
  color: blue;
  // padding-bottom: 10px;
  line-height: 1.6;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
}
.howItWorksBtn {
  margin-top: 30px;
  width: 100%;
  border: 1px solid lightgray;
  color: #5f6163;
  // font-size: 18px;
  font-weight: 600;
  padding: 10px 24px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  &:hover {
    color: rgb(0, 73, 255);
    border: 1px solid rgb(0, 73, 255);
    transition: all 0.2s ease-in-out 0s;
    cursor: pointer;
  }
}

.docWrapper {
  border: 1px solid #eee;
  // padding: 16px;
}
.docDesc {
  padding: 16px;
}
.secLink {
  display: flex;
  align-items: center;

  padding: 16px 16px;
  border-bottom: 1px solid #eee;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
.docContainer {
  padding: 16px 0px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.investCard {
  padding: 16px;
  border-bottom: 1px solid #eee;
  &:hover {
    cursor: pointer;
    background-color: #fbfbfb;
  }
}

.investLable {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 16px;
  color: #5f6163;
}
.amountStyle {
  font-weight: 400;
  color: #0049ff;
  line-height: 1;
  font-size: 35px;
  padding-bottom: 30px;
}
.benefitList {
  padding-left: 16px;
  li {
    font-size: 15px;
    margin-top: 4px;
    line-height: 1.4;
  }
}

.modalStyle {
  padding: 20px;
}
.tableStyle {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
}
.selectedMenu {
  border-bottom: 2px solid #0049ff;
  color: #0049ff;
  font-weight: 600;
}

.mobileFarLeftSide {
  display: none;
}

.bottomStyle {
  padding: 0px 7vw;
  // display: grid;
  // grid-template-columns: 0.2fr 1fr;
}
.bottomContent {
  // padding: 20px;
  border-top: 1px solid #eee;
}
.aboutCompany {
  padding: 10px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottomTitle {
  font-size: 25px;
  font-weight: 900;
  // padding-bottom: 16px;
  // padding: 40px 0px;
  padding-top: 40px;
  padding-bottom: 10px;
}
.blueBar {
  border: 2px solid #0049ff;
  width: 10%;
  text-align: center;
  margin-bottom: 60px;
}
.aboutContent {
  display: grid;
  grid-template-columns: 0.6fr 0.6fr 1fr;
  padding-right: 10vw;
  margin-bottom: 60px;
  // :nth-child(1){
  //     background-color: red;
  // }
  // :nth-child(2){
  //     background-color: green;
  // }
  // :nth-child(3){
  //     background-color: blue;
  // }
}
.labelName {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  color: #5f6163;
}
.labelData {
  font-size: 18px;
  // font-weight: 500;
  padding-bottom: 10px;
  color: #5f6163;
  padding-bottom: 30px;
}

.teamWrapper {
  display: flex;
  // grid-template-columns: 1fr 1fr 1fr;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .teamItem {
    width: 33%;
    // background-color: red;
    margin-bottom: 20px;
    text-align: center;
    padding: 30px;
  }
}

// Mobile Version

@media only screen and (max-width: 767px) {
  .headerContainer {
    padding: 20px 20px;
  }
  .headerSubTitle {
    font-size: 16px;
    padding: 0px 20px;
  }
  .iconsDiv {
    display: none;
  }

  .headerTitle {
    font-size: 24px;
  }
  .badgeContainer {
    padding: 0px 20px;
    padding-top: 30px;
    div {
      margin-right: 10px;
      padding: 5px 6px;
      border-radius: 3px;
      font-size: 10px;
      background-color: rgb(238, 238, 238);
      border-color: rgb(238, 238, 238);
      &:hover {
        background-color: rgb(217, 216, 216);
        border-color: rgb(217, 216, 216);
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
  .templateContainer {
    // padding: 30px 15vw;
    // // padding-left: 15vw;
    // display: grid;
    // grid-template-columns: 2.5fr 1fr;
    // gap: 90px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    gap: 20px;
  }
  .slideContainer {
    height: 100%;
    :nth-child(1) {
      height: 200px !important;
      border-radius: 8px;
    }
  }
  .totalAmount {
    font-size: 24px;
    // padding: 0px 20px;
  }
  .totalAmountSub {
    font-size: 16px;
    // padding: 0px 20px;
  }
  .statsMobile {
    display: flex;
    justify-content: space-between;
    .sectionContainer {
      padding: 10px 0px;
    }
    hr {
      // width: 100%;
      margin: 0px 20px;
    }
  }
  .stickyHeader {
    display: none;
  }
  .templateContainer1 {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    .farLeftSide {
      display: none;
    }
  }
  .mobileFarLeftSide {
    background-color: white;
    padding: 0px 20px;
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    // padding: 20px 20px;
    // .farLeftSide{
    //     display: none;
    // }
    border: 1px solid #eee;
    border-width: 1px 0px 1px 0px;
    padding: 20px 0px;
    select {
      border: none;
      width: 60%;
      font-size: 20px;
      // border-right: 1px solid #eee;
      padding: 0px 20px;
    }
  }
  .leftSide {
    padding: 20px;
  }
  .rightSide {
    padding: 20px;
  }
  .iconsDivMobile {
    display: flex;
    align-items: center;
    font-size: 30px;
    padding-right: 20px;
    border-left: 1px solid #eee;
    padding-left: 20px;
    :nth-child(1) {
      margin-right: 20px;
      &:hover {
        cursor: pointer;
        color: #0049ff;
      }
    }
    :nth-child(2) {
      &:hover {
        cursor: pointer;
        color: #0049ff;
      }
    }
  }
  .vipCard {
    width: 100%;
    margin-bottom: 20px;
  }
  .vipCardContent {
    width: 100%;
    padding: 20px;
    :nth-child(1) {
      font-size: 16px;
    }
    :nth-child(2) {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .vipCardIcon {
    width: 100%;
    height: 100px;
    background-size: 100%;
    background-position: 50% 50%;
    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
  .contentText {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .termItem {
    flex-direction: column;
    padding: 10px 0px;
  }
  .termDesc {
    padding-bottom: 0px;
  }
  .howItWorksBtn {
    margin-top: 20px;
  }
  .docContainer {
    padding: 10px 0px;
    font-size: 14px;
  }
  .investCard {
    padding: 10px;
  }
  .investLable {
    font-size: 14px;
  }
  .amountStyle {
    font-size: 24px;
    padding-bottom: 20px;
  }
  .benefitList {
    li {
      margin-top: 2px;
    }
  }
  .modalStyle {
    padding: 10px;
  }
  .tableStyle {
    padding: 6px 0px;
  }
}

.offerings {
  height: 100%;

  & > .settingsNavbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 1rem 3rem;
    // padding-right: 3rem;
    position: relative;

    .settingsIcon {
      max-width: 18%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: #5f6163;
      margin-right: 1rem;
      img {
        width: 80%;
        height: 80%;
        min-width: 160px;
        min-height: 40px;
      }
    }

    .searchSettings {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: #fff;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #5f6163;
        font-weight: 700;
        opacity: 0.25;
        justify-content: space-between;
        padding-right: 15px;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #5f6163;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #5f6163;
          font-weight: 700;
          background: transparent;
        }
      }
    }

    .settingsListBtn {
      position: absolute;
      right: 3rem;
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #4caf50;
      opacity: 0.9;
      border-radius: 35px;
      cursor: pointer;
      font-weight: 600;
      color: white;
      font-size: 1rem;
      // margin-left: 20vw;
      white-space: nowrap;
      transition: all ease-in 0.2s;

      &:hover {
        scale: 1.05;
      }
    }
  }

  .wSpace {
    width: 100%;
    height: 5.65%;
    min-height: 5.65%;
    max-height: 5.65%;
    padding: 0rem 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: max-content;
      gap: 1.5rem;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;

      .tabs {
        font-size: 0.9rem;
      }

      .selectedTab {
        background: rgba(237, 237, 237, 0.37);
        border-radius: 25px;
        padding: 0rem 2rem;
        height: 90%;
        font-size: 0.9rem;
      }

      .eachOption {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 1.5rem;
        // pointer-events: none;
        p {
          margin-bottom: 0;
          color: #5f6163 !important;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          align-items: center;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }

        img {
          height: 1.1rem;
          object-fit: contain;
        }
      }

      .eachOptionSeleted {
        font-weight: 600;
        background: rgba(237, 237, 237, 0.37);
        border-radius: 25px;
        color: #5f6163;
      }
      .topIcons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  & > .offeringsParent {
    display: flex;
    height: 88%;
    width: 100%;

    & > .offeringsOverflow {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      overflow: hidden;
      // border: 2px solid red;
      transition: all ease-in 0.4s;

      & > .overflowContainer {
        position: absolute;
        inset: 0;
        z-index: 1;
        // background: red;
      }

      & > .offeringsRight {
        position: absolute;
        background: white;
        right: 0;
        top: 13.65% !important;
        bottom: 0;
        z-index: 3;
        width: 40%;
        // min-width: 35%;
        max-width: 600px;
        border-left: 0.5px solid #e5e5e5;
        color: #5f6163 !important;
        padding: 0 1.5rem;
        height: 100%;

        & > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > .title {
            height: 158px;
            font-size: 30px;
            font-weight: 600;
            padding-bottom: 48px;
            padding-top: 38px;
            color: #5f6163 !important;
          }

          & > :nth-child(2) {
            background: #e7e7e7;
            border: 0.5px solid #e7e7e7;
            border-radius: 5px;
            font-size: 1.4rem;
            width: 2.25rem;
            height: 2.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }

        & > .options {
          & > .eachOption {
            font-size: 1.2rem;
            padding: 1.2rem 1.5rem;
            background: #ffffff;
            border: 0.5px solid #e7e7e7;
            border-radius: 35px;
            margin-bottom: 1.5rem;
            cursor: pointer;
            transition: all ease-in 0.2s;
            font-weight: 500;

            &:hover {
              background: rgba(237, 237, 237, 0.37);
            }
          }
        }
      }
    }

    & > .offeringsOverflowHidden {
      top: 0;
      bottom: 0;
      left: 150%;
      // left: auto;
      width: 600px;
    }
  }
}
