@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.terminalsSidebar {
  overflow: hidden;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .loadingAnim {
    position: absolute;
    background: #ffffff80;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .loadingText {
      position: absolute;
      left: 0;
      bottom: 10%;
      right: 0;
      margin: auto;
      text-align: center;
      font-size: 24px;
    }
  }
  .header {
    flex: 0 0 90px;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 35%;
      height: auto;
    }
    .app,
    .coin {
      flex: 0 0 90px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include btn-animate;
      .icon {
        height: 40px;
        width: 40px;
      }
      span {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3px;
        line-height: 1;
        font-size: 11px;
        font-weight: 600;
        text-align: center;
      }
    }
    .app {
      border-right: 1px solid $border-color;
    }
    .coin {
      border-left: 1px solid $border-color;
    }
  }
  .tableHead {
    height: 30px;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    padding: 0 40px;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    span {
      flex: 0 0 33.33%;
      text-align: center;
      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
  .stepsRed,
  .stepsGreen {
    flex: 0 0 30%;
    max-height: calc(30% - 74px);
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .step {
      display: flex;
      padding: 0 40px;
      font-size: 13px;
      flex: 0 0 16.667%;
      max-height: 16.667%;
      font-weight: 600;
      align-items: center;
      span {
        flex: 0 0 33.33%;
        text-align: center;
        &:first-of-type {
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
  .stepsRed {
    span {
      color: red;
    }
  }
  .stepsGreen {
    span {
      color: #4caf50;
    }
  }
  .centerValue {
    flex: 0 0 44px;
    border-bottom: 1px solid $border-color;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    font-weight: 600;
  }
  .txnCard {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    .subTab {
      display: flex;
      flex: 0 0 50px;
      border-bottom: 1px solid $border-color;
      padding: 0px 40px;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      .tabSubItm {
        flex: 0 0 30.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        opacity: 0.4;
        transition: all 400ms ease;
        height: 33px;

        &:hover,
        &.true {
          transform: scale(1.05);

          opacity: 1;
          background: rgba(229, 229, 229, 0.25);
          border-radius: 35px;
          font-weight: 600;
        }
      }
    }
    .cardIn {
      height: 0;
      flex: 1;
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .topBuySell {
        display: flex;
        height: 40px;
        border: 1px solid $border-color;
        border-radius: 35px;
        overflow: hidden;
        .btnBuy,
        .btnSell {
          flex: 0 0 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          transition: all 400ms ease;
          &:hover {
            font-size: 20px;
          }
        }
        .btnBuy {
          background: #4caf50;
          color: white; @include btn-animate($primary-green);
          &.true {
            background: #4caf50;
            color: white;
          }
        }
        .btnSell {
          @include btn-animate($primary-red);
          &.true {
            background: $primary-red;
            color: white;
          }
        }
      }
      .inpCard,
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        border: 1px solid $border-color;
        border-radius: 35px;
        padding: 0 30px;
        font-size: 20px;
      }
      .inpCard {
        .label {
          color: #343c5b;
          font-weight: 600;
          font-size: 17px;
        }
        // .btn{
        //   background: #4CAF50;
        // }
        .inp {
          width: 0;
          flex: 1;
          padding: 0 20px;
          border: none;
          background: none;
          text-align: right;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &:focus {
            &::placeholder {
              color: transparent;
            }
          }
        }
        .coin {
          color: $primary-color;
          font-weight: 500;
          font-size: 17px;
          min-width: 50px;
          text-align: right;
          font-weight: bold;
        }
      }
      .btn {
        height: 40px;
        font-weight: bold;
        @include btn-animate(white);
        color: white;
        &.Buy {
          background: #4caf50;
          // background-color: $primary-green;
        }
        &.Sell {
          background: $primary-red;
          background-color: $primary-red;
        }
        &:hover {
          font-size: 23px;
        }
      }
    }
  }
}
