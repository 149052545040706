@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.marketAssetDetailFinancialsWrap {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 1;
  .marketAssetDetailFinancials {
    margin: 40px 40px 0 40px;
    border: 1px solid $border-color;
    height: 0;
    flex: 1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    flex-direction: column;
    .headerTabs {
      height: 60px;
      border-bottom: 1px solid $border-color;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      overflow: hidden;
      display: flex;
      overflow-x: auto;
      position: relative;
      .tabItm {
        flex-grow: 1;
        border-left: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        padding: 0 15px;
        white-space: nowrap;
        font-size: 16px;
        @include btn-animate;
        &:first-of-type {
          border-left: none;
        }
        &.true {
          font-weight: 18;
          padding: 0 30px;
          font-weight: 700;
        }
      }
      .btnFullScreen {
        border-left: 1px solid $border-color;
        border-top-right-radius: 20px;
        flex: 0 0 60px;
        position: sticky;
        right: 0;
        background: white;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        @include btn-animate;
        img {
          height: 35%;
          width: auto;
        }
      }
    }
    .detailWrap {
      display: flex;
      border-bottom: 1px solid $border-color;
      padding: 30px 80px;
      .detailTexts {
        flex: 0 0 45%;
        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 15px;
          line-height: 1.6;
        }
      }
      .details {
        width: 0;
        flex: 1;
        display: flex;
        .detail {
          flex: 0 0 33.33%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-left: 1px solid $border-color;
          padding-left: 50px;
          .value {
            font-size: 24px;
            font-weight: 600;
          }
          .label {
            font-size: 16px;
          }
        }
      }
    }
    .marketTable {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      .header {
        height: 30px;
        background: white;
        padding: 0 80px;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        justify-content: space-between;
        align-items: center;
        top: 0;
        position: sticky;
        z-index: 1;
        border-bottom: 1px solid $border-color;
        .date,
        .direction,
        .status,
        .amount,
        .price,
        .volume,
        .filled,
        .pending,
        .details {
          display: flex;
          width: 0;
          flex: 1;
        }
        .btnFilter {
          position: absolute;
          height: 15px;
          width: 15px;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
          transition: all 400ms ease;
          &.true {
            transform: rotate(180deg);
          }
        }
      }
      .filtersWrap {
        flex: 0 0 55px;
        border-bottom: 1px solid $border-color;
        padding-left: 80px;
        display: flex;
        .filters {
          width: 0;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .filter {
            border-radius: 18px;
            padding: 0 20px;
            height: 36px;
            border: 1px solid $border-color;
            display: flex;
            align-items: center;
            font-size: 22px;
            margin-right: 20px;
            @include btn-animate;
            img {
              height: 18px;
              width: 18px;
              margin-right: 10px;
            }
            span {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        .input {
          background: none;
          border: none;
          border-left: 1px solid $border-color;
          flex: 0 0 28%;
          padding: 0 60px;
        }
      }
      .liquidList {
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 80px;
        overflow-y: auto;
        .liquidItem {
          height: 90px;
          flex: 0 0 90px;
          background: white;
          display: flex;
          font-size: 14px;
          font-weight: 600;
          justify-content: space-between;
          align-items: center;
          z-index: 1;
          border-bottom: 1px solid $border-color;
          .date,
          .direction,
          .status,
          .amount,
          .price,
          .volume,
          .filled,
          .pending,
          .details {
            display: flex;
            width: 0;
            flex: 1;
          }
          .direction {
            .btnSell {
              padding: 6px 30px;
              background: $primary-red;
              color: white;
              border-radius: 8px;
              @include btn-animate(white);
            }
          }
          .details {
            .btnDetails {
              padding: 6px 30px;
              color: $primary-color;
              border: 1px solid $border-color;
              border-radius: 8px;
              @include btn-animate();
            }
          }
          .btnFilter {
            position: absolute;
            height: 15px;
            width: 15px;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            transition: all 400ms ease;
            &.true {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  &.true {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
