@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

body {
  overflow: hidden;
}

body #root * {
  font-family: Montserrat;
  outline: none;
  box-sizing: border-box;
}

.myGrid {
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 5fr;
  overflow: hidden;
  & > div:first-child {
  }
}

.leftDialogue {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 3vw;
  line-height: 80px;
  /* or 145% */

  display: flex;
  align-items: center;

  color: white;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0px 67px;
}
.wholecard {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  border-radius: 15px;
  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
    cursor: pointer;
  }
  .cardRight {
    width: 584px;
    height: 25vh;
    margin-left: -1px;
    // background: #FFFFFF;
    border: 0.5px solid #e5e5e5;
    border-radius: 0px 15px 15px 0px;

    font-family: 'Montserrat';
    font-style: normal;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    .title {
      font-weight: 700;
      font-size: 25px;
      line-height: 65px;
      color: #484848;
    }
    .subtitle {
      font-weight: 400;
      font-size: 13px;
      line-height: 32px;
      /* or 246% */

      color: #212529;
    }
  }
}

.smallCard {
  border-radius: 15px;
  height: 138px;
  width: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
    cursor: pointer;
  }
}

.stepOneTitle {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 70px;
  color: #212529;
  font-weight: 700;
  font-size: 18px;
}

.stepTwoCard {
  height: 109px;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 26px 33px;

  .name {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #212529;
    padding-bottom: 5px;
  }
  .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #212529;
  }
}

.stepOneInput {
  height: 83px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  align-items: center;
  input {
    border: none;
    width: 90%;
    border-radius: 15px;
    height: 80px;
    padding-left: 20px;
    font-size: 17px;
    &::placeholder {
      font-weight: 700;
      font-size: 17px;
      color: #212529;
      opacity: 0.25;
    }
  }
}

.colouredButton {
  background: #4caf50;
  border-radius: 15px;
  height: 66px;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
    cursor: pointer;
  }
}
.whiteButton {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  height: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-right: 20px;
  color: #212529;
  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
    cursor: pointer;
  }
}

.redDot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}

.greenDot {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
}

.fixedFooterText {
  position: absolute;
  bottom: 0;
  left: 15%;
  padding-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  cursor: pointer;
  color: #212529;
  &:hover {
    font-weight: 700;
  }
}

.otpView {
  height: 0;
  // width: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0;
  // .label {
  //   font-size: 22px;
  //   font-weight: 600;
  //   margin-bottom: 10%;
  // }
  .otpInputWrapper {
    width: 100%;
    // justify-content: space-between;
    // margin-bottom: 4%;
    > div {
      // flex: 0 0 16.6666667%;
      height: 75px;
      // width: 75px;
      .otpInput {
        width: 100% !important;
        height: 100% !important;
        border: 2px solid #e7e7e780;
        border-radius: 8px;
        font-size: 28px;
      }
    }
  }
}

.full-loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  // z-index: 99999999;
  .full-loading-logo {
    height: 65px;
    animation: leaves 0.75s infinite alternate;
  }
}

@keyframes leaves {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.mobileHome {
  display: none;
}
.mobileStepOne {
  display: none;
}
.dynamicViewPort {
  display: none;
}
.desktopUI {
  display: block;
}
.rightWidth {
  max-width: 500px;
  min-width: 450px;
}
.mobileRightSection {
  display: none;
}

@media only screen and (max-width: 600px) {
  .myGrid {
    display: none;
  }
  .mobileHome {
    display: block;
  }
  .mobileLogoSection {
    // height: 91px;
    padding: 30px 80px;
    border-bottom: 0.5px solid #e7e7e7;
    // margin-bottom: 2vh;
  }
  .mobileHeader {
    padding-bottom: 5vh;
    .stepTitle {
      font-style: normal;
      font-weight: 800;
      font-size: 50px;
      line-height: 80px;
    }
    .stepSubtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
    }
  }
  .mobileCardWrapper {
    // padding-top: 90px;
    & > div {
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 10px;
      height: 94px;
      margin-bottom: 21px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 25px;
      & > div:first-child {
        font-weight: 700;
        font-size: 18px;
      }
      & > div {
        font-weight: 400;
        font-size: 10px;
        padding-top: 5px;
      }
    }
  }

  // Next Step

  .rightWidth {
    min-width: auto;
    max-width: auto;
  }

  .desktopUI {
    display: none;
  }
  .stepOneInput {
    height: 67px;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    input {
      border: none;
      width: 90%;
      border-radius: 15px;
      height: 60px;
      padding-left: 20px;
      font-size: 17px;
      &::placeholder {
        font-weight: 700;
        font-size: 17px;
        color: #212529;
        opacity: 0.25;
      }
    }
  }

  .fullButton {
    font-weight: 700;
    font-size: 23px;
    border-radius: 10px;
    color: white;
    height: 61px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
  }
  .halfButtons {
    font-weight: 700;
    font-size: 23px;
    display: flex;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    & > div:first-child {
      height: 61px;
      width: 50%;
      background: #ffffff;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      color: #212529;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
    & > div {
      border-radius: 10px;
      height: 61px;
      width: 50%;

      border-radius: 5px;
      border: 1px solid #e7e7e7;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // position: absolute;
    // bottom: 0;
  }
  .mobileRightSection {
    display: block;
    padding: 27px;
    width: 100%;
    height: 100vh;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
