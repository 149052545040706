.sidebarbuy {
  color: #343C5B;
  background-color: white;

  & > :first-child {
    height: 95%;
  }
  .sidebarhead {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    width: 356px;
    .mainlogo {
      margin: 85px 65px 30px 0;
      width: 57%;
    }
  }
  .breadcrumb {
    width: 350px;
    padding-top: 12px;
    padding-left: 43px;
    cursor: pointer;
    background-color: white;
  }
  .breadCrumbText:hover {
    font-weight: 700 !important;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }

  .menu1 {
    margin: 0 43px 0 43px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    .subhead {
      margin-top: 25px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
    .subheadBalance {
      margin-top: 25px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      width: 50%;
      text-align: right;
    }
    .transaction {
      margin: 20px 0px 20px 0px;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 15px;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      .coin {
        display: flex;
        justify-content: center;
        cursor: pointer;
        width: 100px;
        height: 32px;
        left: 206px;
        top: 304px;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 15px;
        padding: 10px;
      }
      .coin1 {
        display: flex;
        justify-content: center;
        cursor: pointer;
        width: 120px;
        height: 32px;
        left: 206px;
        top: 304px;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 15px;
        padding: 10px;
      }
      .coin:hover,
      .coin1:hover {
        background-color: black;
        color: white;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
      }

      .coinimgsmall {
        height: 15px;
        width: 15px;
      }
      .selectAsset {
        font-weight: 800;
        font-size: 11px;
        line-height: 13px;
        margin-top: 15px;
      }
      .coinsymbolsmall {
        font-weight: 800;
        font-size: 11px;
        line-height: 13px;
        margin-left: 5px;
      }
    }
    .transc {
      border: none;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #343C5B;
      opacity: 1;
      width: 130px;
    }
    .transc::placeholder {
      opacity: 0.25;
    }

    .transc:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.25;
    }

    .transc::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.25;
    }

    .perc {
      cursor: not-allowed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 75px;
      height: 32px;
      left: 39px;
      top: 382px;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      opacity: 0.25;
    }
    .perc:hover {
      color: #ffffff;
      background: black;
    }
  }

  .footer1 {
    background-color: white;
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    position: fixed;
    bottom: 0;
    height: 4rem;
    width: 22%;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    .back {
      height: 4rem;
      width: 50%;
      color: #343C5B;
      background-color: white;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tradebtn {
      height: 4rem;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(209, 206, 206, 0.782);
      text-align: center;
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}

@media screen and (min-height: 991px) {
  .sidebarbuy {
    .menu1 {
      overflow: hidden;
      height: 100%;
    }
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.buyotclogo {
  width: 100%;
  background-color: white;
  border-radius: 25px;
  .otclogoimg {
    position: absolute;
    top: 30%;
    left: 20%;
    animation: heartbeat 1.3s infinite;
    width: 60%;
  }
}

.congratscard {
  margin-top: 5rem;
  .successData {
    margin: 10px 43px 0 43px;
  }

  .congratsText {
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
  }

  .coinVal {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .congratshead {
    margin-top: 50px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  .transactionA {
    margin: 20px 0px 20px 0px;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    .coinA {
      display: flex;
      justify-content: center;
      cursor: pointer;
      width: 100px;
      height: 32px;
      left: 206px;
      top: 304px;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 15px;
      padding: 10px;
    }

    .coinA:hover {
      background-color: black;
      color: white;
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }

    .coinimgsmallA {
      height: 15px;
      width: 15px;
    }
    .coinsymbolsmallA {
      font-weight: 800;
      font-size: 11px;
      line-height: 13px;
      margin-left: 5px;
    }
  }
}

.footer2 {
  background-color: white;
  border-top: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  position: fixed;
  bottom: 0;
  height: 4rem;
  width: 22%;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  .back1 {
    height: 4rem;
    width: 50%;
    color: #343C5B;
    background-color: white;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tradebtn1 {
    height: 4rem;
    width: 50%;
    color: white;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.assetcomp {
  height: 100%;
}
