.ecosystem-dection {
  width: 100%;
  height: calc(97vh - 86px);
  max-width: calc(5vw + var(--max-container-width));
  margin: auto;
  margin-top: calc(2vh + 86px);
  position: relative;
}

.ecosystem-dection > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-height: 1200px;
  min-width: 100%;
  margin-top: 20px;
}

.ecosystem-dection > div > :first-child {
  color: #283790;
  font-size: calc(18px + 2.1vw);
  font-weight: 600;
  height: 10%;
}

.ecosystem-grid-image {
  display: grid;
  height: 60%;
  width: 100%;
  grid-template-columns: repeat(3, 2fr);
  margin: auto;
  gap: 4vw;
  margin-top: 40px;
  align-items: center;
}

.ecosystem-single-container-image {
  //   position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-evenly;
  /* padding: 20px; */
  width: 100%;
  height: 100%;
  //   border-radius: 20px;
  // max-height: 230px;
  min-height: 230px;
  perspective: 1500px;
}

.ecosystem-single-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ecosystem-single-container > :nth-child(1) {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 24px;
}
.ecosystem-single-container-btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  margin: 0 !important;
  width: 100% !important;
  height: calc(100% - 60px);
  max-height: 180px;

  & > div {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 25px;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    max-height: 60px;
    transition: all ease-in 0.2s;

    &:hover {
      color: #5f6163;
      font-weight: 550;
      background: white;
    }
  }
}

.eco-content {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.eco-content > :first-child {
  position: absolute;
  inset: 0;
  font-size: calc(10px + 0.4vw);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 180.9%;
  padding: 10%;
  width: 100%;
  height: 100%;
  margin: auto;
  //   transition: all ease-in-out 1s;
  backface-visibility: hidden;
  //   box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
  //   border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  gap: 1rem;
}

.eco-content > :first-child > img {
  height: 80%;
  width: 80%;
}

.eco-content > :last-child {
  transition: all ease-in-out 1s;
  padding: 1.5rem;
  height: 100%;
  color: #ffffff;
  text-align: left;
  white-space: normal;
  font-size: calc(5px + 0.8vw);
  line-height: 1.5;
  font-weight: 600;
  position: absolute;
  inset: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  //   box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
  //   border-radius: 20px;
  background: #4caf50;
}

.eco-content > :last-child > :last-child {
  cursor: pointer;
  padding: 10px 15px;
  width: max-content;
  white-space: nowrap;
  margin-top: 5%;
  color: white;
  border-radius: 8px;
  //   font-size: calc(13px + 0.2vw);
}

.ecosystem-single-container-image:hover .eco-content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

@media screen and (max-width: 1100px) {
  .ecosystem-dection {
    margin-top: calc(10vh + 100px);
  }
}

@media screen and (max-width: 900px) {
  .ecosystem-single-container-image {
    min-height: 150px;
  }
}

@media screen and (max-width: 770px) {
  .ecosystem-grid-image {
    grid-template-columns: repeat(2, 2fr);
  }

  .ecosystem-single-container-image {
    min-height: 50px;
  }
  .eco-content > :first-child > img {
    width: 100%;
    height: 100%;
  }

  .eco-content > :first-child {
    padding: 3%;
  }
}

@media screen and (max-width: 610px) {
  .eco-content > :first-child > img {
    width: 80%;
    height: 80%;
  }
}
