.discussionContainer {
  padding: 0px 1rem 1rem 3rem;
  border-top: 1px solid #e5e5e5;
  s p {
    font-size: 14px;
    font-weight: 400;
    color: #5f6163;
    line-height: 1.5;
    text-align: center;
    padding-bottom: 30px;
  }
}

.bottomTitle {
  font-size: 25px;
  font-weight: 900;
  // padding-bottom: 16px;
  // padding: 40px 0px;
  padding-top: 40px;
  padding-bottom: 10px;
}
.blueBar {
  border: 2px solid #0049ff;
  width: 10%;
  text-align: center;
  margin-bottom: 60px;
}

.commentWrapper {
  padding-bottom: 30px;
}

.commentItem {
  border-radius: 3px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #efefef;
  padding: 20px;
}
.commenterStyle {
  display: flex;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}
.nameStyle {
  font-size: 16px;
  font-weight: 600;
  color: #5f6163;
  :nth-child(2) {
    font-size: 12px;
    color: #5f6163;
    font-weight: 400;
  }
}
.commentStyle {
  padding: 10px 0px;
}
.likeCommentIcons {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  .likeIcon {
    display: flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
    color: #5f6163;
    :hover {
      opacity: 0.7;
      color: #f83a5c;
      // :nth-child(1){
      //     color: #f83a5c;
      // }
      // :nth-child(2){
      //     margin-right: 5px;
      //     color: #f83a5c;
      // }
    }
    :nth-child(1) {
      margin-right: 5px;
    }
  }
  .commentIcon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    color: #5f6163;
    :hover {
      opacity: 0.7;
    }
    :nth-child(1) {
      margin-right: 5px;
    }
  }
}
.replyContainer {
  padding: 20px;
  // border-radius: 3px;
  border: 1px solid #ddd;
  // border-width: 0px 1px 1px 0px;
  // border-left: 1px solid blue;
  box-shadow: 0 0 10px #efefef;
  word-break: break-word;
  box-shadow: inset 1px 0 0 0 #0049ff;
}
.replyerStyle {
  display: flex;
  // align-items: center;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.replyStyle {
  color: #5f6163;
  font-size: 14px;
  font-weight: 400;
}
