.is-selected {
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    margin-bottom: 20px;
    padding: 10px;
}

.float-panel {
    width: 100%;
    background: white;
    z-index: 300;
    padding: 30px 0;
    transform: translateZ(0);
    transition: all 0.5s;
}

.float-panel .content-area {
    max-width: 900px;
    margin: 10px auto;
}

.float-panel a {
    font-size: 16px;
    text-decoration: none;
    color: #5F6163;
    display: inline-block;
    padding: 10px 20px;
}

.float-panel .fa-gg {
    color: #F0595C;
    font-size: 30px;
    vertical-align: middle;
    transition: all 1s;
}

/* Float Panel: class="float-panel fixed" */

.fixed {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 4px 0;
    animation: slide-down 0.7s;
    opacity: 0.9;
}

.fixed .fa-gg {
    transform: rotate(360deg);
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}